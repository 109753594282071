import { DateTime } from "luxon";
import { BsCashCoin } from "solid-icons/bs";
import { IoAddCircle, IoRemoveCircle } from "solid-icons/io";
import { Component, For, Show, createSignal } from "solid-js";
import { totalFromOrders, type Order as OrderT } from "~/common-types";
import Button from "~/components/Button";
import IconButton from "~/components/IconButton";
import AlertDialog from "./AlertDialog";
import styles from "./Bill.module.css";
import { showToast } from "./Toast";

type BillOrderItem = {
  id: string;
  product_id: string;
  description: string | null;
  quantity: number;
  price: number;
};

const OrderItemComponent: Component<{
  item: BillOrderItem;
  onQuantityChanged: (newQuantity: number) => void;
}> = (props) => {
  return (
    <div
      classList={{
        [styles.orderItem]: true,
      }}
    >
      <div class={styles.quantity}>{props.item.quantity}</div>
      <div
        classList={{
          [styles.name]: true,
        }}
      >
        {props.item.description}
      </div>
      <div>{(props.item.price * props.item.quantity).toFixed(2)}</div>
      <div class={styles.actions}>
        <IconButton
          label="reduce"
          onClick={() => {
            props.onQuantityChanged(props.item.quantity - 1);
          }}
        >
          <IoRemoveCircle />
        </IconButton>
        <IconButton
          label="add"
          onClick={() => {
            props.onQuantityChanged(props.item.quantity + 1);
          }}
        >
          <IoAddCircle />
        </IconButton>
      </div>
    </div>
  );
};

export type BillProps = {
  table: string;
  order: OrderT | undefined;
  onOrderQuantityChange: (id: string, quantity: number) => void;
  clearOrder: () => void;
};

const BillTemporary: Component<BillProps> = (props) => {
  /* State */
  const [showConfirmPayment, setShowConfirmPayment] = createSignal(false);

  /* Computed */
  const total = () => {
    return props.order ? totalFromOrders([props.order]) : 0;
  };

  return (
    <>
      <div data-testid="temporary-bill" class={styles.bill}>
        <div
          classList={{
            [styles.orders]: true,
          }}
        >
          <div class={styles.order}>
            <div class={styles.orderInfo}>
              <div class={styles.sender}></div>
              <div>
                {props.order
                  ? DateTime.fromISO(props.order.created_at).toFormat(
                      "dd/MM/yyyy - HH:mm",
                    )
                  : ""}
              </div>
            </div>
            <Show when={props.order}>
              <For each={props.order?.items}>
                {(orderItem) => (
                  <OrderItemComponent
                    item={orderItem}
                    onQuantityChanged={(newQuantity) => {
                      props.onOrderQuantityChange(orderItem.id, newQuantity);
                    }}
                  />
                )}
              </For>
            </Show>
          </div>
        </div>
        <div class={styles.total}>
          <div class={styles.center}>{total().toFixed(2)}€</div>
          <div class={styles.right}>
            <Button
              label="pay entire order"
              variant="icon"
              onClick={() => setShowConfirmPayment(true)}
            >
              <BsCashCoin />
            </Button>
          </div>
        </div>
      </div>
      <AlertDialog
        open={showConfirmPayment()}
        setOpen={setShowConfirmPayment}
        title="Conferma pagamento"
        description="Confermare il pagamento?"
        showCancelButton={true}
        onCancel={() => {
          setShowConfirmPayment(false);
        }}
        onConfirm={async () => {
          props.clearOrder();
          showToast({ message: "Ordine pagato" });
        }}
      />
    </>
  );
};

export default BillTemporary;
