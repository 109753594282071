import { debounce } from "@solid-primitives/scheduled";
import { action, useAction, useSubmission } from "@solidjs/router";
import {
  Component,
  createEffect,
  createResource,
  createSignal,
  onCleanup,
  untrack,
} from "solid-js";
import { createStore } from "solid-js/store";
import { OrderItem, Result, Table, TableWithNumber } from "~/common-types";
import { showToast } from "~/components/Toast";
import { getCourses } from "~/server/courses";
import {
  getOrderByTableName,
  subscribeToOrderProductsChanges,
  updateOrdersTable,
  newOrder,
} from "~/server/orders";
import { getProducts } from "~/server/products";
import { getTablesByArea } from "~/server/tables";
import { isEqual } from "~/utils";
import CashierComp from "~/components-routes/cashier";

export const route = {};

function useTablesByArea(): [
  () =>
    | {
        [area: string]: Table[];
      }
    | undefined,
  { refetch: () => void },
] {
  const [tablesByArea, setTablesByArea] = createStore<{
    [area: string]: Table[];
  }>({});

  async function fetchTablesByArea() {
    const result = await getTablesByArea();
    setTablesByArea(result);
  }

  const triggerGetTablesByArea = debounce(fetchTablesByArea, 1000);

  const unsubscribe = subscribeToOrderProductsChanges(triggerGetTablesByArea);

  onCleanup(() => {
    triggerGetTablesByArea.clear();
    unsubscribe();
  });

  fetchTablesByArea();

  return [() => tablesByArea, { refetch: fetchTablesByArea }];
}

const Cashier: Component<{}> = () => {
  const [products] = createResource(() => getProducts());
  const [courses] = createResource(() => getCourses());
  const [tablesByArea, { refetch: refetchTablesByArea }] = useTablesByArea();

  const [selectedTable, setSelectedTable] = createSignal<
    TableWithNumber | undefined
  >(undefined);

  const [ordersOfTable, { refetch: refetchOrdersOfTable }] = createResource(
    () => [selectedTable(), Object.values(tablesByArea)] as const,
    async function orderByTableName([table]) {
      if (table == null) return Promise.resolve([]);
      return getOrderByTableName(
        table.name + (table.number != null ? ` ${table.number}` : ""),
      );
    },
  );

  createEffect(function resetSelectedTableWhenOrdersOfTablesIsUpdated() {
    const _selectedTable = untrack(selectedTable);
    const ordersOfTableIds = ordersOfTable()?.map((o) => o.order_id) ?? [];
    if (
      _selectedTable != null &&
      !isEqual(
        _selectedTable.order_ids.slice().sort(),
        ordersOfTableIds.slice().sort(),
      )
    ) {
      setSelectedTable({ ..._selectedTable, order_ids: ordersOfTableIds });
    }
  });

  /* Actions */
  const moveOrdersToTableAction = action(
    async (params: {
      targetTable: TableWithNumber;
      orderIds: string[];
    }): Promise<Result> => {
      const result = await updateOrdersTable({
        tableId: params.targetTable.id,
        orderIds: params.orderIds,
      });
      if (!result.ok) {
        showToast({
          message: "Errore nello spostare gli ordini ad un altro tavolo",
        });
        return { ok: false, error: result.error };
      } else {
        showToast({ message: "Ordini spostati al nuovo tavolo" });
        refetchTablesByArea();
        setSelectedTable(params.targetTable);
        return { ok: true };
      }
    },
  );

  const moveOrdersToTable = useAction(moveOrdersToTableAction);
  const movingOrdersToTable = useSubmission(moveOrdersToTableAction);

  const moveTemporaryOrderToTable = action(
    async (params: {
      table: TableWithNumber;
      orderItems: OrderItem[];
    }): Promise<Result> => {
      const result = await newOrder({
        tableName:
          params.table.name +
          (params.table.number != null ? ` ${params.table.number}` : ""),
        orderState: "TO_PREPARE",
        paid: false,
        toPrint: true,
        orderItems: params.orderItems,
      });
      if (!result.ok) {
        showToast({
          message: "Errore nello spostamento del conto temporaneo",
        });
        return { ok: false, error: result.error };
      } else {
        showToast({ message: "Ordine spostato" });
        refetchTablesByArea();
        setSelectedTable(params.table);
        return { ok: true };
      }
    },
  );
  const moveTemporaryOrderToTableAction = useAction(moveTemporaryOrderToTable);

  return (
    <CashierComp
      products={products}
      courses={courses}
      ordersOfTable={ordersOfTable}
      tablesByArea={tablesByArea}
      selectedTable={selectedTable()}
      refetchOrdersOfTable={refetchOrdersOfTable}
      refetchTablesByArea={refetchTablesByArea}
      setSelectedTable={setSelectedTable}
      moveOrdersToTable={moveOrdersToTable}
      movingOrdersToTable={movingOrdersToTable}
      moveTemporaryOrderToTable={moveTemporaryOrderToTableAction}
    />
  );
};

export default Cashier;
