import { Meta, Title } from "@solidjs/meta";
import { DateTime } from "luxon";
import { FaSolidSpinner } from "solid-icons/fa";
import { RiArrowsArrowLeftLine, RiDocumentFileList3Line } from "solid-icons/ri";
import {
  Component,
  Match,
  Resource,
  Setter,
  Show,
  Suspense,
  Switch,
  createEffect,
  createMemo,
  createSignal,
  untrack,
} from "solid-js";
import { createStore, reconcile } from "solid-js/store";
import {
  Course,
  Order,
  OrderItem,
  Product,
  Result,
  Table,
  TableWithNumber,
  totalFromOrders,
} from "~/common-types";
import Bill from "~/components/Bill";
import BillTemporary from "~/components/BillTemporary";
import Button from "~/components/Button";
import Clock from "~/components/Clock";
import Dialog from "~/components/Dialog";
import Main from "~/components/Main";
import PickProducts from "~/components/PickProducts";
import PickTable from "~/components/PickTable";
import { dispatchPrintPreBill } from "~/events";
import styles from "./cashier.module.css";

export type CashierProps = {
  products: Resource<Product[]>;
  courses: Resource<Course[]>;
  tablesByArea: () => { [area: string]: Table[] } | undefined;
  ordersOfTable: Resource<Order[]>;
  selectedTable: TableWithNumber | undefined;
  refetchTablesByArea: () => void;
  refetchOrdersOfTable: () => void;
  setSelectedTable: Setter<TableWithNumber | undefined>;
  moveOrdersToTable: (params: {
    targetTable: TableWithNumber;
    orderIds: string[];
  }) => Promise<Result>;
  movingOrdersToTable: { pending?: boolean };
  moveTemporaryOrderToTable: (params: {
    table: TableWithNumber;
    orderItems: OrderItem[];
  }) => Promise<Result>;
};

const Cashier: Component<CashierProps> = (props) => {
  /* States */
  const [leftView, setLeftView] = createSignal<"tables" | "products">("tables");
  const [temporaryTable, setTemporaryTable] = createSignal<TableWithNumber>();
  const [temporaryOrderItemsMap, setTemporaryOrderItemsMap] = createSignal<{
    [id: string]: OrderItem;
  }>({}, { equals: false });
  const temporaryOrderOfTable = createMemo<Order | undefined>(() => {
    const table = temporaryTable();
    if (table == null) return undefined;
    return {
      order_id: "temporary",
      created_at: DateTime.local().toISO()!,
      table_name: table.name,
      state: "TO_PREPARE",
      items: Object.values(temporaryOrderItemsMap()),
    };
  });
  const [moveTemporaryOrderIntoTable, setMoveTemporaryOrderIntoTable] =
    createSignal<TableWithNumber | undefined>();

  createEffect(() => {
    const table = props.selectedTable;

    if (
      table == null &&
      temporaryTable() == null &&
      leftView() === "products"
    ) {
      setTemporaryTable({
        id: "temporary",
        name: "Ordine Temporaneo",
        area: "",
        area_order: Infinity,
        number: "",
        order_ids: [],
        total: 0,
      } satisfies TableWithNumber);
    } else if (
      leftView() === "tables" &&
      (temporaryOrderOfTable()?.items ?? []).length === 0
    ) {
      setTemporaryTable(undefined);
    }

    // reset when selecting a new table and we're not in the moveToOtherTable mode
    if (
      moveToOtherTableStore.isModeSelected === false &&
      leftView() === "tables" &&
      table != null
    ) {
      setTemporaryOrderItemsMap(reconcile({}));
    }
  });

  const [moveToOtherTableStore, setMoveToOtherTable] = createStore<{
    isModeSelected: boolean;
    showConfirmDialog: boolean;
    targetTable?: TableWithNumber;
    currentTable?: TableWithNumber;
    targetTableHasOrders: boolean;
  }>({
    isModeSelected: false,
    showConfirmDialog: false,
    targetTable: undefined,
    currentTable: undefined,
    get targetTableHasOrders() {
      const _tablesByArea = props.tablesByArea();
      const area = this.targetTable?.area;
      if (_tablesByArea == null || area == null) return false;
      const targetTableOrderIds =
        _tablesByArea[area]?.find((t) => t.id === this.targetTable?.id)
          ?.order_ids ?? [];
      if (targetTableOrderIds.length > 0) {
        return true;
      }
      return false;
    },
  });

  const [orderItemsMap, setOrderItemsMap] = createStore<{
    [id: string]: OrderItem;
  }>({});

  /* Computed */
  const selectedTableName = () => {
    const table = props.selectedTable;
    if (table == null) return "";
    return table.name + (table.number != null ? ` ${table.number}` : "");
  };

  return (
    <>
      <Title>Cashier</Title>
      <Meta name="description" content="Bar Flemming - Receiver" />
      <Main title="Cassa" backLink="/app/menu">
        <section class={styles.top}>
          <div class={styles.clock}>
            <Clock />
          </div>
          <div class={styles.views}>
            <Button
              type="button"
              variant={leftView() === "tables" ? "primary" : "default"}
              onClick={() => {
                setOrderItemsMap(reconcile({}));
                setLeftView("tables");
              }}
              size="large"
            >
              Tavoli
            </Button>
            <Button
              type="button"
              variant={leftView() === "products" ? "primary" : "default"}
              onClick={() => {
                setMoveToOtherTable({
                  isModeSelected: false,
                });
                setLeftView("products");
              }}
              size="large"
            >
              Prodotti
            </Button>
          </div>
        </section>
        <section class={styles.main}>
          <div
            classList={{
              [styles.mainContent]: true,
              [styles.noPadding]: leftView() === "products",
              [styles.horizontalPaddingZero]: leftView() === "tables",
              [styles.moveToOtherTable]: moveToOtherTableStore.isModeSelected,
            }}
          >
            <Switch>
              <Match when={leftView() === "tables"}>
                <PickTable
                  tablesByArea={props.tablesByArea()}
                  action={{
                    link: false,
                    onClick: (targetTable, currentTable) => {
                      if (currentTable?.id === targetTable.id) {
                        setMoveToOtherTable({
                          isModeSelected: false,
                          showConfirmDialog: false,
                          currentTable: undefined,
                          targetTable: undefined,
                        });
                        props.setSelectedTable();
                        return;
                      }

                      if (moveToOtherTableStore.isModeSelected) {
                        if (temporaryTable()) {
                          setMoveTemporaryOrderIntoTable(targetTable);
                        } else {
                          setMoveToOtherTable({
                            showConfirmDialog: true,
                            targetTable,
                            currentTable,
                          });
                        }
                      } else if (targetTable.id !== props.selectedTable?.id) {
                        props.setSelectedTable(targetTable);
                      }
                    },
                  }}
                  selected={props.selectedTable}
                />
              </Match>
              <Match when={leftView() === "products"}>
                <Suspense
                  fallback={
                    <div class={styles.loading}>
                      <FaSolidSpinner
                        role="spinbutton"
                        class={styles.hourglass}
                        size={32}
                      />
                    </div>
                  }
                >
                  <Switch>
                    <Match when={selectedTableName()}>
                      <PickProducts
                        products={props.products}
                        courses={props.courses}
                        tableName={selectedTableName()}
                        orderItemsMap={orderItemsMap}
                        setOrderItemsMap={setOrderItemsMap}
                        productVariant="left"
                      />
                    </Match>
                    <Match when={temporaryTable()}>
                      <PickProducts
                        products={props.products}
                        courses={props.courses}
                        tableName={temporaryTable()?.name ?? ""}
                        orderItemsMap={temporaryOrderItemsMap()}
                        setOrderItemsMap={setTemporaryOrderItemsMap}
                        productVariant="left"
                      />
                    </Match>
                  </Switch>
                </Suspense>
              </Match>
            </Switch>
          </div>
          <div class={styles.mainContent}>
            <Suspense>
              <Switch
                fallback={
                  <div class={styles.noOrder}>Nessun tavolo selezionato</div>
                }
              >
                <Match when={props.selectedTable}>
                  <Show
                    when={
                      !props.ordersOfTable.error && !props.ordersOfTable.loading
                    }
                    fallback={<div class={styles.noOrder} />}
                  >
                    <header class={styles.header}>
                      <h2 class={styles.title}>
                        {props.selectedTable?.name}{" "}
                        {props.selectedTable?.number}
                      </h2>
                    </header>
                    <Show
                      when={
                        (props.ordersOfTable?.() ?? [])?.length > 0 ||
                        Object.values(orderItemsMap).length > 0
                      }
                      fallback={
                        <div class={styles.noOrder}>Non ci sono ordini</div>
                      }
                    >
                      <Bill
                        table={selectedTableName()}
                        orders={props.ordersOfTable?.() ?? []}
                        afterVariation={props.refetchOrdersOfTable}
                        showHeader={false}
                        newOrder={{
                          orderItemsMap,
                          setOrderItemsMap,
                        }}
                      />
                    </Show>
                  </Show>
                </Match>
                <Match when={temporaryTable() != null}>
                  <header class={styles.header}>
                    <h2 class={styles.title}>Ordine Temporaneo</h2>
                  </header>
                  <Show
                    when={Object.values(temporaryOrderItemsMap())?.length > 0}
                    fallback={
                      <div class={styles.noOrder}>
                        Nessun prodotto nell'ordine temporaneo
                      </div>
                    }
                  >
                    <BillTemporary
                      table={temporaryTable()!.name || ""}
                      order={temporaryOrderOfTable()}
                      onOrderQuantityChange={(id, quantity) => {
                        setTemporaryOrderItemsMap((prev) => {
                          if (quantity === 0) {
                            const { [id]: _, ...rest } = prev;
                            return rest;
                          }

                          return {
                            ...prev,
                            [id]: {
                              ...prev[id],
                              quantity,
                            },
                          };
                        });
                      }}
                      clearOrder={() => {
                        setTemporaryOrderItemsMap(reconcile({}));
                      }}
                    />
                  </Show>
                </Match>
              </Switch>
              <Show
                when={
                  (props.selectedTable != null &&
                    props.ordersOfTable()!.length > 0) ||
                  (temporaryTable() != null &&
                    temporaryOrderOfTable() != null &&
                    temporaryOrderOfTable()!.items.length > 0)
                }
              >
                <div class={styles.cashierActions}>
                  <div class={styles.leftActions}>
                    <Button
                      label="move to other table"
                      variant={
                        moveToOtherTableStore.isModeSelected
                          ? "icon-selected"
                          : "icon"
                      }
                      padding
                      onClick={() => {
                        setLeftView("tables");
                        setMoveToOtherTable("isModeSelected", (v) => !v);
                      }}
                    >
                      <div class={styles.button}>
                        <RiArrowsArrowLeftLine />
                        <span class={styles.text}>Sposta</span>
                      </div>
                    </Button>
                  </div>
                  <div class={styles.centerActions}>
                    <Button
                      label="pre bill"
                      variant="icon"
                      padding
                      onClick={() => {
                        const table =
                          props.selectedTable || untrack(temporaryTable);
                        const ordersOfTable =
                          untrack(props.ordersOfTable) ?? [];
                        const tempOrderOfTable = untrack(temporaryOrderOfTable);
                        const orders =
                          ordersOfTable.length > 0
                            ? ordersOfTable
                            : tempOrderOfTable != null
                            ? [tempOrderOfTable]
                            : [];
                        dispatchPrintPreBill({
                          area_name: table?.area ?? "",
                          table_name: table?.fullName ?? table?.name ?? "",
                          datetime:
                            DateTime.now().toFormat("dd/MM/yyyy - HH:mm"),
                          total: totalFromOrders(orders),
                          products:
                            orders.flatMap((order) =>
                              order.items.map((item) => ({
                                description: item.description ?? "Unknown",
                                quantity: item.quantity,
                                totalPrice: item.price * item.quantity,
                              })),
                            ) ?? [],
                        });
                      }}
                    >
                      <div class={styles.button}>
                        <RiDocumentFileList3Line />
                        <span class={styles.text}>Pre Conto</span>
                      </div>
                    </Button>
                  </div>
                </div>
              </Show>
            </Suspense>
          </div>
        </section>
      </Main>
      <Dialog
        open={moveToOtherTableStore.showConfirmDialog}
        setOpen={(v) => setMoveToOtherTable("showConfirmDialog", v)}
        title="Conferma Spostamento"
        variant="small"
      >
        <div>
          <p>
            {moveToOtherTableStore.targetTableHasOrders
              ? `Confermare l'aggiunta del conto di `
              : `Confermare lo spostamento del conto da `}
            {[
              moveToOtherTableStore.currentTable?.name,
              moveToOtherTableStore.currentTable?.number,
            ]
              .filter(Boolean)
              .join(" ")}
            {moveToOtherTableStore.targetTableHasOrders
              ? ` nel conto già presente in `
              : ` in `}
            {[
              moveToOtherTableStore.targetTable?.name,
              moveToOtherTableStore.targetTable?.number,
            ]
              .filter(Boolean)
              .join(" ")}
            ?
          </p>
          <div class={styles.dialogActions}>
            <Button
              role="button"
              label="Annulla"
              disabled={props.movingOrdersToTable.pending}
              variant="default"
              size="large"
              onClick={() => setMoveToOtherTable("showConfirmDialog", false)}
            >
              No
            </Button>
            <Button
              role="button"
              label="Conferma"
              disabled={props.movingOrdersToTable.pending}
              variant="primary"
              size="large"
              onClick={async () => {
                const result = await props.moveOrdersToTable({
                  orderIds: moveToOtherTableStore.currentTable!.order_ids,
                  targetTable: moveToOtherTableStore.targetTable!,
                });

                if (result.ok) {
                  setMoveToOtherTable({
                    isModeSelected: false,
                    showConfirmDialog: false,
                    currentTable: undefined,
                    targetTable: undefined,
                  });
                }
              }}
            >
              Si
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={moveTemporaryOrderIntoTable() != null}
        setOpen={(v) => {
          if (v) {
            setMoveTemporaryOrderIntoTable(undefined);
          }
        }}
        title="Conferma Spostamento"
        variant="small"
      >
        <div>
          <p>
            Confermare l'aggiunta del conto temporaneo nel conto{" "}
            {[
              moveTemporaryOrderIntoTable()?.name,
              moveTemporaryOrderIntoTable()?.number,
            ]
              .filter(Boolean)
              .join(" ")}
            ?
          </p>
          <div class={styles.dialogActions}>
            <Button
              role="button"
              label="Annulla"
              disabled={props.movingOrdersToTable.pending}
              variant="default"
              size="large"
              onClick={() => setMoveTemporaryOrderIntoTable(undefined)}
            >
              No
            </Button>
            <Button
              role="button"
              label="Conferma"
              disabled={props.movingOrdersToTable.pending}
              variant="primary"
              size="large"
              onClick={async () => {
                const result = await props.moveTemporaryOrderToTable({
                  table: moveTemporaryOrderIntoTable()!,
                  orderItems: Object.values(temporaryOrderItemsMap()),
                });

                if (result.ok) {
                  setTemporaryTable(undefined);
                  setTemporaryOrderItemsMap({});
                  setMoveToOtherTable({
                    isModeSelected: false,
                    showConfirmDialog: false,
                    currentTable: undefined,
                    targetTable: undefined,
                  });
                  setMoveTemporaryOrderIntoTable(undefined);
                }
              }}
            >
              Si
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Cashier;
